import React from 'react'
import { connect, useDispatch } from 'react-redux';
import find from 'lodash/find'

import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material'

import DnsIcon from '@mui/icons-material/Dns';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import useElementWallets from '../../../api/blockchain/useElementWallets';
import useWalletBalance from '../../../api/blockchain/useWalletBalance';
import { CREATE_WALLET_STEP, FUND_WALLET_STEP } from '../../../constants/blockchain';
import { setIssuanceStep } from '../../../store/assetIssuance/assetIssuanceActions';

import BCTable from './Utils/BCTable';
import DataLoading from './Utils/DataLoading';
import DataError from './Utils/DataError';
import NoData from './Utils/NoData';
import CreateWalletDialog from './Wallets/CreateWalletDialog';
import WalletAddressesDialog from './Wallets/WalletAddressesDialog';
import WalletAssetsDialog from './Wallets/WalletAssetsDialog';
import { useDidMountEffect } from '../../../utilities/customHooks';
import { snackbar } from '../../../utilities/snackbarUtils';
import { errorParser } from '../../../components/LiquidForm/utils/errorParser';
import deleteWallet from '../../../api/blockchain/deleteWallet';

// Wallet balance component
const WalletBalance = ({ wallet, refresh }) => {
    const { walletBalance, walletBalanceValidating, walletBalanceError, updateWalletBalance } = useWalletBalance(wallet);

    useDidMountEffect(() => {
        if (refresh) {
            updateWalletBalance()
        }
    }, [refresh])

    if (walletBalanceValidating) {
        return <CircularProgress size={12} />
    }

    if (walletBalanceError) {
        return <Typography variant='caption'>Could not load.</Typography>
    }

    return <span>{walletBalance?.bitcoin}</span>
}

// Main Wallet component
const Wallets = ({ issuanceStep, issuanceItem }) => {

    const [openCreateWallet, setOpenCreateWallet] = React.useState(false);
    const [openAddresses, setOpenAddresses] = React.useState(false);
    const [openAssets, setOpenAssets] = React.useState(false);
    const [activeWallet, setActiveWallet] = React.useState(false);
    const [balanceKey, setBalanceKey] = React.useState(0);

    const [ hidingWallet, setHidingWallet ] = React.useState({});

    const dispatch = useDispatch()

    const { wallets, walletsError, walletsLoading, walletsValidating, updateWallets } = useElementWallets()
    const [walletsWithBalance, setWalletsWithBalance] = React.useState(null);

    React.useEffect(() => {
        if (wallets) {
            let walletData = wallets

            for (let i = 0; i < walletData.length; i++) {
                if(walletData[i].wallet !== 'Counter Balance Issuer Account'){
                    walletData[i].balance = <WalletBalance refresh={balanceKey} wallet={walletData[i].wallet} />
                }
                
            }
            setWalletsWithBalance(walletData)
        }
    }, [wallets, balanceKey]);

    // Open "Create wallet" dialog if the asset issuance step is clicked
    React.useEffect(() => {
        if (issuanceStep === CREATE_WALLET_STEP) {
            handleOpenCreateWallet()
        }
    }, [issuanceStep])

    // Open the "Addresses" dialog of a wallet to facilitate funding
    React.useEffect(() => {
        if (issuanceStep === FUND_WALLET_STEP && issuanceItem) {
            handleOpenAddresses(find(wallets, {
                wallet: issuanceItem
            }))
        }
    }, [issuanceStep, issuanceItem])

    const handleOpenCreateWallet = () => {
        setOpenCreateWallet(true);
    };

    const handleCloseCreateWallet = () => {
        setOpenCreateWallet(false);
    };

    const handleOpenAddresses = (wallet) => {
        setOpenAddresses(true);
        setActiveWallet(wallet)
    };

    const handleCloseAddresses = () => {
        setOpenAddresses(false);
        setActiveWallet(null);
        dispatch(setIssuanceStep({ item: null }))
    };

    const handleOpenAssets = (wallet) => {
        setOpenAssets(true);
        setActiveWallet(wallet)
    };

    const handleCloseAssets = () => {
        setOpenAssets(false);
        setActiveWallet(null);
    };

    const hideWallet = async (wallet) => {
        const name = wallet.wallet;
        setHidingWallet({ ...hidingWallet, [name]: true });
        try {
            await deleteWallet(name);
            await updateWallets();
            snackbar.success('Wallet hidden successfully');
        } catch (error) {
            snackbar.error(errorParser(error));
        } finally {
            setHidingWallet({ ...hidingWallet, [name]: false });
        }
    }

    return (
        <>
            <Typography variant="h6">
                My wallets
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent='space-between' gap={0} sx={{ color: 'gray' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant='body2'>
                        Create and fund your wallets
                    </Typography>
                </div>
                <div>
                    <Button variant="contained" startIcon={<AddIcon />} sx={{ float: 'right' }} onClick={handleOpenCreateWallet}>
                        Create wallet
                    </Button>
                    <Button variant="contained" color='secondary' startIcon={<RefreshIcon />} sx={{ marginRight: '10px', float: 'right' }} onClick={() => setBalanceKey(balanceKey + 1)}>
                        Update balances
                    </Button>
                </div>
            </Stack>
            <br />
            {
                (wallets && wallets.length === 0) &&
                <Alert variant='outlined' severity="info" sx={{ marginBottom: '10px' }}>
                    Create a <b>digital wallet</b> to start your asset issuance journey.
                </Alert>
            }
            {/* Wallet data loading */}
            <DataLoading
                loading={walletsLoading}
                text='Loading your wallets...'
            />
            {/* Wallet data loaded */}
            {
                (!walletsLoading && walletsWithBalance) &&
                <BCTable
                    columns={[
                        { name: 'wallet', label: 'Wallet', bold: true },
                        { name: 'balance', label: 'Balance (L-BTC)' },
                        {
                            name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                <>
                                    <Button variant="contained" size="small" startIcon={<DnsIcon />} sx={{ marginRight: '10px;' }}
                                        onClick={() => handleOpenAddresses(row)}
                                        disabled={hidingWallet[row.wallet]}
                                    >
                                        Addresses
                                    </Button>
                                    <Button variant="outlined" size="small" startIcon={<AccountBalanceWalletIcon />} sx={{ marginRight: '10px;' }}
                                        onClick={() => handleOpenAssets(row)}
                                        disabled={hidingWallet[row.wallet]}
                                    >
                                        Issued assets
                                    </Button>
                                    <Button variant="outlined" size="small" startIcon={hidingWallet[row.wallet] ? <CircularProgress sx={{ marginRight: '8px;', marginLeft: '8px;' }} size={14} color='inherit' /> : <VisibilityOffIcon />}
                                        onClick={() => hideWallet(row)}
                                        disabled={hidingWallet[row.wallet]}
                                    >
                                        Hide
                                    </Button>
                                </>
                            )
                        },

                    ]}
                    data={walletsWithBalance.filter(item => item.wallet !== 'Counter Balance Issuer Account')}
                />
            }
            <br />
            {/* No data */}
            <NoData
                show={!walletsValidating && !walletsError && wallets.length === 0}
                text='You have no active wallets.'
            />
            {/* Wallet error */}
            <DataError
                show={walletsError && !wallets}
                text='Could not load wallets due to error. Please try again.'
            />

            {
                openCreateWallet &&
                <CreateWalletDialog open={openCreateWallet} handleClose={handleCloseCreateWallet} updateWallets={updateWallets} />
            }
            {
                openAddresses &&
                <WalletAddressesDialog open={openAddresses} handleClose={handleCloseAddresses} wallet={activeWallet} />
            }
            {
                openAssets &&
                <WalletAssetsDialog open={openAssets} handleClose={handleCloseAssets} wallet={activeWallet} />
            }
        </>
    )
}

const mapStateToProps = ({ assetIssuance }) => {
    return {
        issuanceStep: assetIssuance.step,
        issuanceItem: assetIssuance.item,
    };
};

export default connect(mapStateToProps, null)(Wallets)