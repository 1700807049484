import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Chart from 'react-apexcharts';
import authStyles from '../authStyles';
import investorStyles from '../../../components/styles/investorStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as kycActions from '../../../store/kyc/kycActions';

const Overview = (props) => {
    const classes = authStyles();
    const investorClasses = investorStyles();
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (props.kyc) {
            console.log(props.kyc);
            setChartData({
                series: [
                    Number(
                        (props.kyc.total_kyc /
                            props.kyc.total_shareholders) *
                            100
                    ),
                    Number(
                        ((props.kyc.total_shareholders -
                            props.kyc.total_kyc) /
                            props.kyc.total_shareholders) *
                            100
                    ),
                ],
                labels: ["KYC'd", "Not KYC'd"],
            });
        }
    }, [props.kyc]);
    useEffect(props.kycActions.getKyc, []);

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid
                    container
                    style={{
                        width: '75%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Grid item xs={12} md={5} sm={5}>
                        <Typography className={classes.title}>
                            Investor Overview
                        </Typography>
                        {props.kyc?.loading ? (
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Grid
                                style={{
                                    marginTop: '2rem',
                                    marginLeft: '1rem',
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        md={7}
                                        className={investorClasses.overviewLeft}
                                    >
                                        Total Holders:{' '}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        className={
                                            investorClasses.overviewRight
                                        }
                                    >
                                        {props.kyc?.total_shareholders ?? 0}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        md={7}
                                        className={investorClasses.overviewLeft}
                                    >
                                        Total KYC'd: {'     '}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        className={
                                            investorClasses.overviewRight
                                        }
                                    >
                                        {props.kyc?.total_kyc ?? 0}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {' '}
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        md={7}
                                        className={investorClasses.overviewLeft}
                                    >
                                        Total not KYC'd:{' '}
                                    </Grid>{' '}
                                    <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        className={
                                            investorClasses.overviewRight
                                        }
                                    >
                                        {props.kyc?.total_not_kyc ?? 0}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={5} sm={5}>
                        {props.kyc && !props.kyc.loading && chartData && (
                            <React.Fragment>
                                <Chart
                                    options={{
                                        labels: chartData.labels,
                                        colors: ['#3E9CD9', '#E2E2E2'],
                                        plotOptions: {
                                            pie: {
                                                donut: {
                                                    labels: {
                                                        show: true,
                                                        value: {
                                                            show: true,
                                                        },
                                                        total: {
                                                            show: false,
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                    series={chartData.series}
                                    width="100%"
                                    type="donut"
                                    height={250}
                                />
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ profile, kyc }) => {
    return {
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        kycActions: bindActionCreators(kycActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
