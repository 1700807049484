import React, { useEffect, useState } from 'react';
import request from '../../../utilities/request';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authStyles from '../authStyles';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import * as config from './../../../config';
import Humanize from 'humanize-plus';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckboxWithLabel } from 'formik-mui';
import Container from '@mui/material/Container';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import DownloadCapTable from '../../../components/DownloadCapTable';
import { format } from 'date-fns';
import DataTable from '../../../components/DataTable/MuiDataTable';
import {
    useDebouncedEffect,
    useDidMountEffect,
} from '../../../utilities/customHooks';

import {
    downloadCapTableTemplate,
    formatNumber,
    getColumnDisplayValue
} from '../../../utilities/utils';
import TextField from '../../../components/TextField/TextField';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import Row from './Row';
import * as capTableActions from '../../../store/capTable/capTableActions';
import * as profileActions from '../../../store/profile/profileActions';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
const LargeTableCell = styled(TableCell)({
    '&.MuiTableCell-body': {
        width: 150,
    },
    borderBottom: 0,
});
const SmallTableCell = styled(TableCell)({
    '&.MuiTableCell-body': {
        width: 10,
    },
    borderBottom: 0,
});
const formData = new FormData();
formData.append('cap_table', '');
const CapTableList = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isFullyDiluted, setIsFullyDiluted] = useState(true);
    const [date, setDate] = useState(new Date());

    const [uploadDialog, setUploadDialog] = useState(false);
    const [total, setTotal] = useState({
        a: 0,
        p: 0,
    });

    useEffect(props.capTableActions.getSummary, []);

    const handleChange = (event) => {
        setIsFullyDiluted(!isFullyDiluted);
        setDate(new Date());
        //setDisplayCategoryColumns(false);
    };

    const handleDateChange = (date) => {
        setDate(new Date(date));
        //setDisplayCategoryColumns(false);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isCapTableUploading, setIsCapTableUploading] = useState(false);
    //const [displayAcquiredColumns, setDisplayAcquiredColumns] = useState(false);
    //const [displayCategoryColumns, setDisplayCategoryColumns] = useState(false);
    //const [originalData, setOriginalData] = useState([]);
    const [rewriteCapTable, setRewriteCapTable] = useState(false);
    const [newCapTable, setNewCapTable] = useState('');
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(
        false
    );
    const [reloadTable, setReloadTable] = useState(true);
    const uploadFile = (file) => {
        if (
            file.type !== 'text/csv' &&
            file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            enqueueSnackbar(
                'Only .csv and  .xlx, .xls, .xlsx files are allowed',
                {
                    variant: 'error',
                }
            );

            setTimeout(function () {
                closeSnackbar();
            }, 2000);
        } else {
            formData.set('cap_table', file, file.name);
            formData.set('rewrite', rewriteCapTable);
            setNewCapTable(file.name);
        }
    };

    const confirmUpload = () => {
        if (rewriteCapTable) {
            setDisplayConfirmationDialog(true);
        } else {
            uploadCapTable();
        }
    };

    const uploadCapTable = () => {
        setIsCapTableUploading(true);
        request
            .post(`${config.API_BASE_URL}/cap-table`, formData)
            .then((response) => {
                setIsCapTableUploading(false);
                enqueueSnackbar('Cap Table has been uploaded', {
                    variant: 'success',
                });

                setIsCapTableUploading(false);
                setDisplayConfirmationDialog(false);
                setUploadDialog(false);
                setReloadTable(!reloadTable);
            })
            .catch((error) => {
                setIsCapTableUploading(false);
                if (error.response.data.message) {
                    enqueueSnackbar(error.response.data.message, {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(
                        'An error occurred while uploading cap table',
                        {
                            variant: 'error',
                        }
                    );
                }
            })
            .finally(() => {
                setNewCapTable('');
                setIsCapTableUploading(false);
                setDisplayConfirmationDialog(false);
                setRewriteCapTable(false);
            });
    };

    useDebouncedEffect(
        () => {
            try {
                let d = format(date, 'MM/dd/yyyy');

                async function fetchData() {
                    setIsLoading(true);
                    const response = await request.get(
                        `${
                            config.API_BASE_URL
                        }/cap-table?vesting=${!isFullyDiluted}&date=${d}`
                    );
                    //setOriginalData(response.data);
                    let processed_data = [];
                    let idx = 0;
                    if (response && response.data) {
                        for (var i = 0; i < response.data.length; i++) {
                            for (
                                var j = 0;
                                j < response.data[i].categories.length;
                                j++
                            ) {
                                processed_data[idx] = {
                                    class_description:
                                        response.data[i].class_description,
                                    class: response.data[i].class,
                                    'class_%': response.data[i]['class_%'],
                                    class_amount: response.data[i].class_amount,
                                    class_id: response.data[i].class_id,
                                    'category_%':
                                        response.data[i].categories[j][
                                            'category_%'
                                        ],
                                    category_amount:
                                        response.data[i].categories[j]
                                            .category_amount,
                                    category_description:
                                        response.data[i].categories[j]
                                            .category_description,
                                    category_id:
                                        response.data[i].categories[j]
                                            .category_id,
                                    investors:
                                        response.data[i].categories[j]
                                            .investors,
                                    current_price: response.data[i].categories[
                                        j
                                    ].current_price
                                        ? response.data[i].categories[j]
                                              .current_price
                                        : null,
                                    class_price: response.data[i].class_price
                                        ? response.data[i].class_price
                                        : null,
                                    authorized_shares: response.data[i]
                                        .authorized_shares
                                        ? response.data[i].authorized_shares
                                        : null,
                                };
                                idx++;
                            }
                        }
                    }
                    setTableData(processed_data);
                    setData(response.data);
                    setIsLoading(false);
                }
                fetchData();
            } catch (e) {}
        },
        500,
        [date, reloadTable]
    );
    useDidMountEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/cap-table?vesting=${!isFullyDiluted}`
            );

            setData(response.data);
            setIsLoading(false);
        }
        fetchData();
    }, [isFullyDiluted]);

    useEffect(() => {
        if (data) {
            let t = {
                a: 0,
                p: 0,
            };
            for (let shareClass of data) {
                t.a += shareClass.class_amount;
                t.p += shareClass['class_%'];
            }

            if (t.p > 100) {
                t.p = 100;
            }

            setTotal(t);
        }
    }, [data]);

    const displayUploadDialog = () => {
        setUploadDialog(true);
    };

    const updatePreferredCapTableColums = (preferred_columns) => {
        const preferredColumns = preferred_columns.reduce((column, item) => {
            column[item.name] = item.display;
            return column;
        }, {});

        props.profileActions.updatePreferredTableColumns(
            preferredColumns
        );  
    }
    
    return (
        <div>
            {/** will be added later */}
            {/*<InvestorHQ /> */}
            <Grid
                container
                style={{ justifyContent: 'space-between', width: '83rem' }}
            >
                <span className="flex-left">
                    <span>
                        Fully diluted{' '}
                        <HelpTooltip title="A “fully-diluted“ capitalization table shows, in addition to all outstanding assets, the number of assets issuable upon exercise or conversion of any options or warrants." />
                    </span>
                    <Switch
                        checked={!isFullyDiluted}
                        onChange={handleChange}
                        name="isFullyDiluted"
                    />
                    &nbsp;
                    <span>
                        As vested{' '}
                        <HelpTooltip title="An “As Vested“ capitalization table shows the ownership as of today's date, without taking into effect the dillutive effect of any outstanding options and warrants." />
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.12)' }}>
                        &nbsp;{' | '}&nbsp;
                    </span>
                    <span>Total Assets: {Humanize.formatNumber(total.a)}</span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.12)' }}>
                        &nbsp;{' | '}&nbsp;
                    </span>
                    <span>
                        Total Assets %: {Humanize.formatNumber(total.p, 2)}%
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.12)' }}>
                        &nbsp;{' | '}&nbsp;
                    </span>
                    <span>
                        Total Authorized Assets:{' '}
                        {Humanize.formatNumber(
                            props.profile && props.profile.authorized_shares
                                ? props.profile.authorized_shares
                                : 0
                        )}
                    </span>
                     <span style={{ color: 'rgba(0, 0, 0, 0.12)' }}>
                        &nbsp;{' | '}&nbsp;
                    </span>
                    <span>
                        Holders: {props.dashSummary?.data?.totalInvestors}
                   </span>
                </span>

                <DownloadCapTable />
            </Grid>
            {!isFullyDiluted && (
                <>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {' '}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) => (
                                    <TextField {...props} />
                                )}
                                label="Date"
                                fullWidth
                                minDate={'01/01/2020'}
                                value={date}
                                onChange={(date) => handleDateChange(date)}
                            />
                        </LocalizationProvider>
                        <Button
                            style={{ marginLeft: 10, marginTop: 5, height: 40 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => setDate(new Date())}
                        >
                            Today
                        </Button>
                    </Grid>
                    <br />
                </>
            )}
            {isLoading ? (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <React.Fragment style={{ width: '83rem' }}>
                    <div
                        style={{
                            marginBottom: 10,
                            width: '83rem',
                            marginLeft: '-1.5rem',
                        }}
                    >
                        <DataTable
                            data={tableData ? tableData : []}
                            title="Cap Table"
                            columns={[
                                {
                                    label: 'Class',
                                    name: 'class_description',
                                    setCellProps: () => ({
                                        style: {
                                            minWidth: '800px',
                                            maxWidth: '800px',
                                        },
                                    }),
                                    options: {
                                        customBodyRender: (value) => (
                                            <LargeTableCell
                                                xs={{ align: 'left' }}
                                            >
                                                {value}
                                            </LargeTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'class_description')
                                    },
                                },
                                {
                                    label: 'Authorized assets',
                                    name: 'authorized_shares',
                                    options: {
                                        customBodyRender: (value) => (
                                            <SmallTableCell>
                                                {value
                                                    ? formatNumber(value)
                                                    : '-'}
                                            </SmallTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'authorized_shares')
                                    },
                                },
                                {
                                    label: 'Outstanding assets',
                                    name: 'class_amount',
                                    options: {
                                        customBodyRender: (value) => (
                                            <SmallTableCell>
                                                {formatNumber(value)}
                                            </SmallTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'class_amount')
                                    },
                                },

                                {
                                    label: 'Par value per asset',
                                    name: 'class_price',
                                    options: {
                                        customBodyRenderLite: (dataIndex) => {
                                            const row = tableData[dataIndex];
                                            return (
                                                <SmallTableCell>
                                                    {row.class_price &&
                                                    row.equity_main_currency
                                                        ? `${formatNumber(
                                                              row.class_price
                                                          )} ${
                                                              row.equity_main_currency
                                                          }`
                                                        : '-'}
                                                </SmallTableCell>
                                            );
                                        },
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'class_price')
                                    },
                                },
                                {
                                    label: 'Category',
                                    name: 'category_description',
                                    options: {
                                        customBodyRender: (value) => (
                                            <LargeTableCell
                                                xs={{ align: 'left' }}
                                            >
                                                {value}
                                            </LargeTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'category_description')
                                    },
                                },

                                {
                                    label: 'Assets',
                                    name: 'category_amount',
                                    options: {
                                        customBodyRender: (value) => (
                                            <SmallTableCell
                                                xs={{ align: 'left' }}
                                            >
                                                {Humanize.formatNumber(
                                                    value,
                                                    2
                                                )}
                                            </SmallTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'category_amount')
                                    },
                                },
                                {
                                    label: '% Owned',
                                    name: 'category_%',
                                    options: {
                                        customBodyRender: (value) => (
                                            <SmallTableCell
                                                xs={{ align: 'left' }}
                                            >
                                                {value}%
                                            </SmallTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'category_%')
                                    },
                                },
                                {
                                    label: 'Current Price',
                                    name: 'current_price',
                                    options: {
                                        customBodyRender: (value) => (
                                            <SmallTableCell
                                                xs={{ align: 'left' }}
                                            >
                                                {value
                                                    ? formatNumber(value)
                                                    : '-'}
                                            </SmallTableCell>
                                        ),
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'current_price')
                                    },
                                },

                                {
                                    label: 'Investors',
                                    name: 'investors',
                                    options: { display: 'excluded' },
                                },
                                {
                                    label: 'Acquired Price',
                                    name: 'investors.acquired_price',
                                    options: {
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'investors.acquired_price')
                                    },
                                },
                                {
                                    label: 'Acquired Date',
                                    name: 'investors.acquired_date',
                                    options: {
                                        display: getColumnDisplayValue(props.profile?.preferred_cap_table_columns, 'investors.acquired_date')
                                    },
                                },
                            ]}
                            expandable={{
                                expandableRows: true,
                                expandableRowsHeader: false,
                                expandableRowsOnClick: true,
                                rowsExpanded: true,

                                renderExpandableRow: (rowData, rowMeta) => {
                                    return (
                                        <React.Fragment>
                                            {rowData[8].map((row, id) => (
                                                <Row
                                                    investor={row}
                                                    key={id}
                                                    class_description={''}
                                                />
                                            ))}
                                        </React.Fragment>
                                    );
                                },
                            }}
                            updatePreferredColums = {
                                updatePreferredCapTableColums
                            }
                        />
                    </div>
                    <br />
                    <Grid item xs={12} style={{ width: '83rem' }}>
                        <Typography
                            variant="body2"
                            className={classes.centerText}
                        >
                            <span
                                className="no-decoration link"
                                onClick={displayUploadDialog}
                            >
                                Upload Cap Table
                            </span>
                        </Typography>
                    </Grid>
                </React.Fragment>
            )}

            <Dialog
                maxWidth="sm"
                open={uploadDialog}
                onBackdropClick={(e) => setUploadDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Upload Cap Table
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography>
                                Please download and use the template provided to
                                create and upload your own cap table:
                            </Typography>
                            <DialogActions
                                className={classes.actions}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.secondaryButtonLabel}
                                    onClick={() => {
                                        downloadCapTableTemplate();
                                    }}
                                    style={{ marginRight: '5px' }}
                                >
                                    <b>Download Template</b>
                                </Button>

                                {isCapTableUploading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <ButtonGroup>
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.buttonLabel}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Browse
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0]
                                                    );
                                                }}
                                                style={{
                                                    display: 'none',
                                                }}
                                                type="file"
                                                name="new_logo"
                                                accept=".csv,.xlx,.xls,.xlsx"
                                            />
                                        </Button>
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            color="primary"
                                            disabled={newCapTable === ''}
                                            className={classes.button}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            type="submit"
                                            onClick={() => confirmUpload()}
                                        >
                                            Confirm Upload
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </DialogActions>
                            <Grid>
                                {newCapTable !== '' ? (
                                    <Typography component="div">
                                        File name: <b>{newCapTable}</b>
                                    </Typography>
                                ) : null}
                                <br />
                            </Grid>
                            <Grid container spacing={1}>
                                <Formik
                                    initialValues={{
                                        rewrite_cap_table: rewriteCapTable,
                                    }}
                                >
                                    <Form>
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name="rewrite_cap_table"
                                            onChange={() =>
                                                setRewriteCapTable(
                                                    !rewriteCapTable
                                                )
                                            }
                                            Label={{
                                                label: (
                                                    <div
                                                        className={
                                                            classes.checkbox
                                                        }
                                                    >
                                                        I would like to{' '}
                                                        <b>REMOVE </b>
                                                        the existing data in the
                                                        cap table.
                                                    </div>
                                                ),
                                            }}
                                            checked={rewriteCapTable}
                                            required
                                        />
                                    </Form>
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={displayConfirmationDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Confirmation Dialog
                </DialogTitle>
                <DialogContent>
                    <Typography component="h2" style={{ color: '#ED7571' }}>
                        <b>
                            {' '}
                            Are you sure that you want to REMOVE the existing
                            data in the cap table?
                        </b>
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{ confirm_deletion: null }}
                                onSubmit={(values, { setSubmitting }) => {
                                    let confirm_deletion = '';
                                    if (
                                        values['confirm_deletion'] !== null &&
                                        values['confirm_deletion'] !== undefined
                                    ) {
                                        confirm_deletion = values[
                                            'confirm_deletion'
                                        ].toLowerCase();
                                    }

                                    if (confirm_deletion === 'remove') {
                                        uploadCapTable();
                                    } else {
                                        enqueueSnackbar(
                                            'Please type "remove" in the box to confirm request',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="confirm_deletion"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={
                                                    <span>
                                                        Type{' '}
                                                        <b className="ihq-red">
                                                            remove
                                                        </b>{' '}
                                                        in the box to confirm
                                                        your request
                                                    </span>
                                                }
                                                fullWidth
                                            />
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        setDisplayConfirmationDialog(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
const mapStateToProps = ({ profile, capTable }) => {
    return {
        profile: profile,
        dashSummary: capTable.summary,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
        profileActions: bindActionCreators(profileActions, dispatch)

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CapTableList);
