import React, { useState, useEffect } from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import { isViewMode } from '../../../utilities/utils';
import * as registry from '../../../utilities/registry';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../../../components/DataTable/MuiDataTable';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import LockedTile from '../../../components/LockedTile';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormikAutocomplete from '../../../components/FormikAutocomplete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Divider from '@mui/material/Divider';
import CategoriesWithActionsRow from './CategoriesWithActionsRow';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import TextField from '../../../components/TextField/TextField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import authStyles from '../authStyles';
import { parseError, formatNumber } from '../../../utilities/utils';
import ClassDialog from '../../../components/InfoDialog/ClassDialog';
import { RESTRICTED, UNRESTRICTED, CUSTOM } from '../../../constants/equities';
import {
    CLASS_DESCRIPTION_MAXIMUM_LENGTH,
    CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
} from '../../../constants/assets';
import RadioButtons from '../../../components/RadioButtons';
import EditAuthorizedSharesDialog from './EditAuthorizedSharesDialog';
import { yellow } from '@mui/material/colors';
let classInitialValues = {
    class_description: '',
    class: 'Ordinary',
    class_amount: '',
    class_price: '',
    equity_main_currency: null,
    amount_invested: null,
    valuation: null,
    name: null,
    exclude_from_equity: false,
};
const ManageClassInfo = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editAuthorizedSharesDialogIsOpen, setEditAuthorizedSharesDialogIsOpen] = useState(false)
    const [classData, setClassData] = useState([]);
    const [key, setKey] = useState(0);
    const [infoDialogIsOpen, setInfoDialogIsOpen] = useState(false);
    const [assetsChanged, setAssetsChanged] = useState(false);
    const [addCategoryDialog, setAddCategoryDialog] = useState(false);
    const [shareClass, setShareClass] = useState(null);
    const [excludingOption, setExcludingOption] = useState(true);
    const [classType, setClassType] = useState('Common');
    const [taClasses, setTaClasses] = useState(null);
    const [listClasses, setListClasses] = useState(
        props.classes ? props.classes.data : []
    );

    const [assetsValue, setAssetsValue] = useState(null)
    const [assetsError, setAssetsError] = useState(false);

    const { capTableActions, capTable } = props;
    const [classTableKey, setClassTableKey] = useState(1);
    const [classCategoriesData, setClassCategoriesData] = useState(
        capTable ? capTable : []
    );

    let [defaultCurrency, setDefaultCurrency] = useState(null);

    const classValidationSchema = Yup.object({
        class: Yup.string()
            .required('Please select the class.')
            .max(20, 'Class type be longer than 20 characters.'),
        class_description: Yup.string()
            .required('Please enter the class description.')
            .max(20, 'Class description cannot be longer than 20 characters.'),
        /*equity_main_currency: Yup.string()
            .required('Please select the currency.')
            .max(5, 'Currency ticker cannot have more than 5 characters.'),*/
        //.nullable(),
        // class_amount: Yup.string().required('Please enter the class amount')
        //                  .matches(/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/, 'Please enter a valid amount.'),
        //class_price: Yup.string().required('Please enter a numeric value.'),
        class_price: Yup.string().when([], {
            is: () => classType !== 'Custom' && classType !== 'SAFE',
            then: Yup.string().required('Please enter a numeric value'),
            otherwise: Yup.string().notRequired(),
        }),
        //class_price: Yup.string('Please enter a numeric value.'),
    });

    const categoryValidationSchema = Yup.object({
        category_description: Yup.string().required(
            'Please enter the category name.'
        ),
        /*category_class_name: Yup.string().required(
            'Please select a corresponding class.'
        ),*/
        // category_amount: Yup.string()
        //     .required('Please enter the category amount')
        //     .matches(
        //         /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
        //         'Please enter a valid amount.'
        //     ),
        transfer_restrictions: Yup.string()
            .oneOf([UNRESTRICTED, RESTRICTED, CUSTOM])
            .required('Please select the transfer restriction type.'),
        lock_period: Yup.number()
            .nullable()
            .when('transfer_restrictions', {
                is: CUSTOM,
                then: Yup.number().required('Please enter the lock period.'),
            }),
    });

    useEffect(capTableActions.getClasses, []);
    useEffect(capTableActions.getClassCategories, []);
    useEffect(() => {
        setListClasses(props.classes.data);
    }, [props.classes]);
    useEffect(() => {
        if (props.categories) {
            if (capTable) {
                let newArr = [];
                for (var i = 0; i < capTable.length; i++) {
                    newArr[i] = capTable[i];
                    newArr[i].categories = [];
                    for (var j = 0; j < props.categories.length; j++) {
                        if (
                            capTable[i].id ===
                            props.categories[j].share_class_id
                        ) {
                            newArr[i].categories.push(props.categories[j]);
                        }
                    }
                }
                setClassCategoriesData(newArr);
            }
        }
    }, [props.categories, capTable]);

    const displayAddDialog = () => {
        setAddDialog(true);
    };

    const displayEditClassDialog = (item) => {
        let data = Object.assign({}, item);
        if (data.class_price === null) {
            data.class_price = '';
        } else {
            data.class_price = formatNumber(data.class_price);
        }

        if (data.class_amount === null) {
            data.class_amount = '';
        } else {
            data.class_amount = formatNumber(data.class_amount);
        }

        if (data.authorized_shares === null) {
            data.authorized_shares = '';
        } else {
            data.authorized_shares = formatNumber(data.authorized_shares);
        }

        setAssetsValue(data.authorized_shares)
        setClassData(data);
        setExcludingOption(data.exclude_from_equity);
        if (data.class === 'Safe') {
            setClassType('SAFE');
        } else {
            setClassType(data.class);
        }
        setEditDialog(true);
    };

    const displayDeleteClassDialog = (item) => {
        setClassData(item);
        setDeleteDialog(true);
    };

    const closeDialog = () => {
        setAddDialog(false);
        setEditDialog(false);
        setDeleteDialog(false);
        setAddCategoryDialog(false);
        setExcludingOption(true);
        setClassType('Common');
        setAssetsError(false);
        setAssetsChanged(false)
        setAssetsValue(null)
    };

    const openInfoDialog = (row) => {
        setInfoDialogIsOpen(true);
        setClassData(row);
    };

    const displayAddCategoryDialog = () => {
        setAddCategoryDialog(true);
        setShareClass(
            capTable && capTable.length > 0
                ? capTable[0].class_description
                : null
        );
    };

    const newCategorySuccessCallback = () => {
        props.capTableActions.getClassCategories();
        setAddCategoryDialog(false);
    };

    const changeExcludingOption = (e) => {
        setExcludingOption(e.target.checked);
    };

    if (capTable && taClasses === null) {
        if (localStorage.getItem('ta_token')) {
            let cls = [];
            cls = capTable.filter((element) => {
                return localStorage
                    .getItem('managed_by_current_ta')
                    .split(',')
                    .map(function (item) {
                        return parseInt(item, 10);
                    })
                    .includes(element.id);
            });

            setTaClasses(cls);
            setListClasses(cls);
        }
    }

    useEffect(() => {
        for (var i = 0; i < registry.countryCurrencies.length; i++) {
            if (
                registry.countryCurrencies[i].id ===
                props.profile.incorporation_country
            ) {
                setDefaultCurrency(registry.countryCurrencies[i].name);
            }
        }
    }, [defaultCurrency]);

    const handleAssetsChange = (assets) => {
        if (assetsValue !== assets){
            setAssetsChanged(true)
        } else {
            setAssetsChanged(false)
        }
    }

    return (
        <div key={classTableKey}>
            <Grid container style={{ width: '100%' }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div
                            onClick={() => displayAddDialog()}
                            className={classes.buttonLabel}
                        >
                            Create New Class
                        </div>
                        <div>
                            <span>
                                <SubdirectoryArrowRightIcon
                                    style={{ color: '#A1A097' }}
                                />
                            </span>
                            <span
                                onClick={() => displayAddCategoryDialog()}
                                className={classes.buttonLabel}
                            >
                                New Category
                            </span>{' '}
                            <span>
                                {' '}
                                <HelpTooltip
                                    color="#3E9CD9"
                                    marginLeft="-4px"
                                    title="Categories are not required, but can be used to further track various types of holders within a class"
                                />
                            </span>
                        </div>
                    </Grid>
                </Grid>
                <Divider
                    orientation="vertical"
                    flexItem
                    fullWidth
                    style={{ marginRight: '1rem' }}
                ></Divider>
                <Grid item xs={12} sm={9} md={9}>
                    {props.classes.loading ? (
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            justifyContent="center"
                        >
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <DataTable
                                title={'Asset Classes'}
                                data={
                                    classCategoriesData &&
                                    classCategoriesData.message === null
                                        ? []
                                        : taClasses
                                        ? taClasses
                                        : capTable
                                }
                                columns={[
                                    {
                                        label: 'Class Description',
                                        name: 'class_description',
                                        options: {
                                            customBodyRenderLite: (
                                                dataIndex
                                            ) => {
                                                const row = capTable[dataIndex];
                                                return (
                                                    <span
                                                        className="hoverable"
                                                        onClick={() =>
                                                            openInfoDialog(row)
                                                        }
                                                    >
                                                        {row
                                                            ? row.class_description
                                                            : ''}
                                                    </span>
                                                );
                                            },
                                        },
                                    },
                                    {
                                        label: 'Class',
                                        name: 'class',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value.toUpperCase()}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Outstanding assets',
                                        name: 'class_amount',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {formatNumber(value)}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Authorized assets',
                                        name: 'authorized_shares',
                                        options: {
                                            customBodyRenderLite: (
                                                dataIndex
                                            ) => {
                                                const row = capTable[dataIndex];                                               
                                                return (
                                                        <span>
                                                        {row.authorized_shares
                                                            ? <>
                                                                {
                                                                    formatNumber(
                                                                        row.authorized_shares
                                                                    )
                                                                }
                                                                {(+(row.class_amount ?? 0) > +row.authorized_shares) && (
                                                                    <Tooltip title="Number of outstanding shares surpasses authorized shares">
                                                                        <IconButton
                                                                            variant="contained"
                                                                            sx={{color: yellow[700]}}
                                                                            size="small"
                                                                            className="m-r-5"
                                                                        >
                                                                            <ErrorIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                            : 'N/A'}
                                                       </span>
                                                );
                                            },
                                        },
                                    },
                                    {
                                        label: 'Par value per asset',
                                        name: 'class_price',
                                        options: {
                                            customBodyRenderLite: (
                                                dataIndex
                                            ) => {
                                                const row = capTable[dataIndex];
                                                return (
                                                    <span>
                                                        {row.class_price &&
                                                        row.equity_main_currency
                                                            ? `${formatNumber(
                                                                  row.class_price
                                                              )} ${
                                                                  row.equity_main_currency
                                                              }`
                                                            : 'N/A'}
                                                    </span>
                                                );
                                            },
                                        },
                                    },
                                    {
                                        label: 'Actions',
                                        name: 'edit',
                                        options: {
                                            download: false,
                                            print: false,
                                            customBodyRender: (value) => {
                                                return (
                                                    <LockedTile
                                                        lock={
                                                            isViewMode(
                                                                props.profile
                                                            ) &&
                                                            props.profile.classes_managed_by_ta.includes(
                                                                value.class_id
                                                            )
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <Tooltip title="Edit">
                                                                <IconButton
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    className="m-r-5"
                                                                    disabled={
                                                                        value.issue_tx
                                                                    }
                                                                    onClick={() => {
                                                                        displayEditClassDialog(
                                                                            value
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    size="small"
                                                                    className="m-r-5"
                                                                    disabled={
                                                                        value.issue_tx
                                                                    }
                                                                    onClick={() => {
                                                                        displayDeleteClassDialog(
                                                                            value
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    </LockedTile>
                                                );
                                            },
                                        },
                                    },
                                    {
                                        label: 'Categoris',
                                        name: 'categories',
                                        options: { display: 'excluded' },
                                    },
                                ]}
                                expandable={{
                                    expandableRows: true,
                                    expandableRowsHeader: false,
                                    expandableRowsOnClick: true,
                                    rowsExpanded: true,
                                    renderExpandableRow: (rowData, rowMeta) => {
                                        //const colSpan = rowData.length + 1;
                                        /*rowData[6].forEach((row) => {
                                            row.category_price = formatNumber(
                                                row.category_price
                                            );
                                            row.current_price = formatNumber(
                                                row.current_price
                                            );
                                            row.category_amount = formatNumber(
                                                row.category_amount
                                            );
                                        });*/
                                        return (
                                            <React.Fragment>
                                                {rowData[6].map((row, id) => (
                                                    <CategoriesWithActionsRow
                                                        categories={row}
                                                        key={id}
                                                        setClassTableKey={
                                                            setClassTableKey
                                                        }
                                                        classTableKey={
                                                            classTableKey
                                                        }
                                                    />
                                                ))}{' '}
                                            </React.Fragment>
                                        );
                                    },
                                }}
                                shareSort={2}
                            />
                            <Grid item xs={12} className="m-t-10">
                                <small>
                                    * Click on a class description to see more
                                    information.
                                </small>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={addDialog}
                        onClose={(event, reason) => {
                            if (reason === 'backdropClick') {
                                setAddDialog(false)
                            }
                        }}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            New Class
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={classInitialValues}
                                        validationSchema={classValidationSchema}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            if (
                                                classType !== 'Custom' &&
                                                classType !== 'SAFE' &&
                                                (values['class_price'] === '' ||
                                                    values['class_price'] ===
                                                        null ||
                                                    values['class_price'] ===
                                                        undefined ||
                                                    values['class_price'] <= 0)
                                            ) {
                                                setSubmitting(false);
                                                enqueueSnackbar(
                                                    'Please check price field',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            } else {
                                                let data = {
                                                    description:
                                                        values[
                                                            'class_description'
                                                        ],
                                                    class: classType,
                                                    equity_main_currency: values[
                                                        'equity_main_currency'
                                                    ]
                                                        ? values[
                                                              'equity_main_currency'
                                                          ]
                                                        : defaultCurrency
                                                        ? defaultCurrency
                                                        : props.initialValues
                                                              .currency
                                                        ? props.initialValues
                                                              .currency
                                                        : null,
                                                    price: values['class_price']
                                                        ? values['class_price']
                                                        : null,
                                                    authorized_shares: values[
                                                        'authorized_shares'
                                                    ]
                                                        ? values[
                                                              'authorized_shares'
                                                          ]
                                                        : null,
                                                    amount_invested: values[
                                                        'amount_invested'
                                                    ]
                                                        ? values[
                                                              'amount_invested'
                                                          ]
                                                        : null,
                                                    valuation: values[
                                                        'valuation'
                                                    ]
                                                        ? values['valuation']
                                                        : null,
                                                    name: values['name']
                                                        ? values['name']
                                                        : null,
                                                    exclude_from_equity: excludingOption,
                                                };

                                                request
                                                    .post(
                                                        `${config.API_BASE_URL}/share-classes`,
                                                        data
                                                    )
                                                    .then((res) => {
                                                        props.capTableActions.getClasses();
                                                        setAddDialog(false);
                                                        setClassType('Common');
                                                        setExcludingOption(
                                                            true
                                                        );
                                                        setAssetsError(false);
                                                        enqueueSnackbar(
                                                            'Class added successfully',
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        let errorMessage = parseError(
                                                            error
                                                        );
                                                        enqueueSnackbar(
                                                            errorMessage,
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );
                                                        setSubmitting(false);
                                                    });
                                            }
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid>
                                                    <Grid>
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="class"
                                                            variant="outlined"
                                                            inputProps={{
                                                                id: 'class',
                                                            }}
                                                            fullWidth
                                                            required
                                                            label="Class *"
                                                            value={classType}
                                                            onChange={(e) =>
                                                                setClassType(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                key={'1'}
                                                                value={'Common'}
                                                            >
                                                                {'Common'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'2'}
                                                                value={
                                                                    'Ordinary'
                                                                }
                                                            >
                                                                {'Ordinary'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'3'}
                                                                value={
                                                                    'Preferred'
                                                                }
                                                            >
                                                                {'Preferred'}
                                                            </MenuItem>
                                                            {/*<MenuItem
                                                                key={'4'}
                                                                value={'SAFE'}
                                                            >
                                                                {'SAFE'}
                                                            </MenuItem>*/}
                                                            <MenuItem
                                                                key={'5'}
                                                                value={
                                                                    'Convertible'
                                                                }
                                                            >
                                                                {'Convertible'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'6'}
                                                                value={'Custom'}
                                                            >
                                                                {'Custom'}
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>

                                                    {classType === 'Custom' && (
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="text"
                                                            name="name"
                                                            variant="outlined"
                                                            margin="normal"
                                                            label={'Class Name'}
                                                            placeholder={
                                                                'Custom Name'
                                                            }
                                                            fullWidth
                                                        />
                                                    )}

                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="class_description"
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        label={
                                                            'Class Description'
                                                        }
                                                        placeholder={
                                                            'Apple Common A'
                                                        }
                                                        fullWidth
                                                        inputProps={{
                                                            maxLength: CLASS_DESCRIPTION_MAXIMUM_LENGTH,
                                                        }}
                                                    />
                                                    <Field
                                                        component={
                                                            FormikAutocomplete
                                                        }
                                                        name="equity_main_currency"
                                                        variant="outlined"
                                                        label="Currency"
                                                        margin="normal"
                                                        fullWidth
                                                        inputProps={{
                                                            id:
                                                                'equity_main_currency',
                                                        }}
                                                        optionKey="id"
                                                        dataKey="id"
                                                        required
                                                        options={
                                                            registry.currencies
                                                        }
                                                        value={
                                                            defaultCurrency
                                                                ? defaultCurrency
                                                                : props
                                                                      .initialValues
                                                                      .currency
                                                        }
                                                    />

                                                    {classType === 'Custom' ||
                                                    classType === 'SAFE' ? (
                                                        <Grid>
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                name="amount_invested"
                                                                variant="outlined"
                                                                margin="normal"
                                                                label={
                                                                    'Amount Invested'
                                                                }
                                                                numeric={1}
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                fullWidth
                                                            />

                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                numeric={1}
                                                                name="valuation"
                                                                variant="outlined"
                                                                margin="normal"
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: {
                                                                        pointerEvents:
                                                                            'auto',
                                                                    },
                                                                }}
                                                                label={
                                                                    <div>
                                                                        Valuation
                                                                        <HelpTooltip
                                                                            color="#3E9CD9"
                                                                            title="Used to calculate an illustrative ownership % for the holders for these SAFE terms"
                                                                        />
                                                                    </div>
                                                                }
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <Grid>
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                numeric={1}
                                                                name="class_price"
                                                                variant="outlined"
                                                                margin="normal"
                                                                label={
                                                                    'Par value per asset'
                                                                }
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                //numeric={1}
                                                                fullWidth
                                                            />
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                numeric={1}
                                                                name="authorized_shares"
                                                                variant="outlined"
                                                                margin="normal"
                                                                label={
                                                                    <div>
                                                                        Authorized
                                                                        Assets
                                                                        <HelpTooltip
                                                                            color="#3E9CD9"
                                                                            title="Authorized assets refer to the total number of assets of stock that a company is legally authorized to issue."
                                                                        />
                                                                    </div>
                                                                }
                                                                InputLabelProps={{
                                                                    style: {
                                                                        pointerEvents:
                                                                            'auto',
                                                                    },
                                                                }}
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                //numeric={1}
                                                                fullWidth
                                                            />

                                                            {assetsError && (
                                                                <span
                                                                    className={
                                                                        classes.errorMessage
                                                                    }
                                                                >
                                                                    *Maximum
                                                                    length is 8
                                                                    digit
                                                                </span>
                                                            )}
                                                        </Grid>
                                                    )}

                                                    {classType === 'Custom' && (
                                                        <FormControlLabel
                                                            label="Exclude this class from the equity calculation (e.g.debt)"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        changeExcludingOption
                                                                    }
                                                                    checked={
                                                                        excludingOption
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <DialogActions
                                                    className={classes.actions}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        className={
                                                            classes.secondaryButtonLabel
                                                        }
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        {!isSubmitting ? (
                                                            <span>Save</span>
                                                        ) : (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={editDialog}
                        onBackdropClick={(e) => setEditDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            Edit Class
                            <IconButton
                                className={classes.closeButton}
                                onClick={closeDialog}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={classData}
                                        validationSchema={classValidationSchema}
                                        key={key}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            if (
                                                classType !== 'Custom' &&
                                                classType !== 'SAFE' &&
                                                (values['class_price'] === '' ||
                                                    values['class_price'] ===
                                                        null ||
                                                    values['class_price'] ===
                                                        undefined ||
                                                    values['class_price'] <= 0)
                                            ) {
                                                enqueueSnackbar(
                                                    'Please check price field',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            } else {
                                                let data = {
                                                    description:
                                                        values[
                                                            'class_description'
                                                        ],
                                                    class: classType,
                                                    equity_main_currency:
                                                        values[
                                                            'equity_main_currency'
                                                        ],
                                                    price: values['class_price']
                                                        ? values['class_price']
                                                        : null,
                                                    authorized_shares: values[
                                                        'authorized_shares'
                                                    ]
                                                        ? values[
                                                              'authorized_shares'
                                                          ]
                                                        : null,
                                                    amount_invested: values[
                                                        'amount_invested'
                                                    ]
                                                        ? values[
                                                              'amount_invested'
                                                          ]
                                                        : null,

                                                    valuation: values[
                                                        'valuation'
                                                    ]
                                                        ? values['valuation']
                                                        : null,
                                                    name: values['name']
                                                        ? values['name']
                                                        : null,
                                                    class_id:
                                                        classData['class_id'],
                                                    exclude_from_equity: excludingOption,
                                                };
                                                request
                                                    .patch(
                                                        `${config.API_BASE_URL}/share-classes/edit`,
                                                        data
                                                    )
                                                    .then((response) => {
                                                        enqueueSnackbar(
                                                            'Asset class has been updated successfully',
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                        setEditDialog(false);
                                                        setExcludingOption(
                                                            true
                                                        );
                                                        setAssetsChanged(false)
                                                        setClassData([]);
                                                        setClassType('Custom');
                                                        props.capTableActions.getClasses();
                                                    })
                                                    .catch((error) => {
                                                        let errorMessage = parseError(error)
                                                        setSubmitting(false);
                                                        enqueueSnackbar(
                                                            errorMessage ? errorMessage : 'Error occured while updating asset class',
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );
                                                        setAssetsChanged(false)
                                                        setKey(key + 1);
                                                    });
                                            }
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid>
                                                    <Grid>
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="class"
                                                            variant="outlined"
                                                            inputProps={{
                                                                id: 'class',
                                                            }}
                                                            label="Class*"
                                                            fullWidth
                                                            value={classType}
                                                            onChange={(e) =>
                                                                setClassType(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                key={'1'}
                                                                value={'Common'}
                                                            >
                                                                {'Common'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'2'}
                                                                value={
                                                                    'Ordinary'
                                                                }
                                                            >
                                                                {'Ordinary'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'3'}
                                                                value={
                                                                    'Preferred'
                                                                }
                                                            >
                                                                {'Preferred'}
                                                            </MenuItem>
                                                            {/*<MenuItem
                                                                key={'4'}
                                                                value={'SAFE'}
                                                            >
                                                                {'SAFE'}
                                                            </MenuItem>*/}
                                                            <MenuItem
                                                                key={'5'}
                                                                value={
                                                                    'Convertible'
                                                                }
                                                            >
                                                                {'Convertible'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'6'}
                                                                value={'Custom'}
                                                            >
                                                                {'Custom'}
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    {classType === 'Custom' && (
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="text"
                                                            name="name"
                                                            variant="outlined"
                                                            margin="normal"
                                                            label={'Class Name'}
                                                            placeholder={
                                                                'Custom Name'
                                                            }
                                                            fullWidth
                                                        />
                                                    )}

                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="class_description"
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        label={
                                                            'Class Description'
                                                        }
                                                        placeholder={
                                                            'Apple Common A'
                                                        }
                                                        fullWidth
                                                    />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        numeric={1}
                                                        name="authorized_shares"
                                                        variant="outlined"
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            style: {
                                                                pointerEvents:
                                                                    'auto',
                                                            },
                                                        }}
                                                        onKeyUp={(e) =>
                                                            handleAssetsChange(e.target.value)
                                                        }
                                                        label={
                                                            <div>
                                                                Authorized
                                                                Assets
                                                                <HelpTooltip
                                                                    color="#3E9CD9"
                                                                    title="Authorized assets refer to the total number of assets of stock that a company is legally authorized to issue."
                                                                />
                                                            </div>
                                                        }
                                                        fullWidth
                                                    />
                                                    {assetsError && (
                                                        <span
                                                            className={
                                                                classes.errorMessage
                                                            }
                                                        >
                                                            *Maximum length is 8
                                                            digit
                                                        </span>
                                                    )}

                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="class_amount"
                                                        variant="outlined"
                                                        displayOnly
                                                        margin="normal"
                                                        label={
                                                            'Number of assets in class'
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />

                                                    <Field
                                                        component={
                                                            FormikAutocomplete
                                                        }
                                                        name="equity_main_currency"
                                                        variant="outlined"
                                                        label="Currency"
                                                        margin="normal"
                                                        fullWidth
                                                        inputProps={{
                                                            id:
                                                                'equity_main_currency',
                                                        }}
                                                        optionKey="id"
                                                        dataKey="id"
                                                        required
                                                        options={
                                                            registry.currencies
                                                        }
                                                    />

                                                    {classType === 'Custom' ||
                                                    classType === 'SAFE' ? (
                                                        <Grid>
                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                name="amount_invested"
                                                                variant="outlined"
                                                                margin="normal"
                                                                label={
                                                                    'Amount Invested'
                                                                }
                                                                numeric={1}
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                fullWidth
                                                            />

                                                            <Field
                                                                component={
                                                                    TextField
                                                                }
                                                                type="text"
                                                                name="valuation"
                                                                variant="outlined"
                                                                margin="normal"
                                                                placeholder={
                                                                    '0.00'
                                                                }
                                                                numeric={1}
                                                                required
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: {
                                                                        pointerEvents:
                                                                            'auto',
                                                                    },
                                                                }}
                                                                label={
                                                                    <div>
                                                                        Valuation
                                                                        <HelpTooltip
                                                                            color="#3E9CD9"
                                                                            title="Used to calculate an illustrative ownership % for the holders for these SAFE terms"
                                                                        />
                                                                    </div>
                                                                }
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="text"
                                                            numeric={1}
                                                            name="class_price"
                                                            variant="outlined"
                                                            margin="normal"
                                                            label={
                                                                'Par value per asset'
                                                            }
                                                            placeholder={'0.00'}
                                                            fullWidth
                                                            required
                                                        />
                                                    )}

                                                    {classType === 'Custom' && (
                                                        <FormControlLabel
                                                            label="Exclude this class from the equity calculation (e.g.debt)"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        changeExcludingOption
                                                                    }
                                                                    checked={
                                                                        excludingOption
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                {assetsChanged && 
                                                    <Typography component="h2" style={{ color: '#ED7571' }}>
                                                        <br/><b>This will update the amount of Authorized Assets within this class. Save to confirm.</b>
                                                    </Typography>
                                                }                                               
                                                <DialogActions
                                                    className={classes.actions}
                                                >
                                                   
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        className={
                                                            classes.secondaryButtonLabel
                                                        }
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog maxWidth="sm" fullWidth open={deleteDialog}>
                        <DialogTitle className={classes.dialogTitle}>
                            Delete Class
                        </DialogTitle>
                        <DialogContent>
                            <Typography
                                component="h2"
                                style={{ color: '#ED7571' }}
                            >
                                <b>
                                    {' '}
                                    Are you sure that you want to delete{' '}
                                    {classData.class_description} class? All
                                    related information will be deleted
                                    permanently.
                                </b>
                            </Typography>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={classData}
                                        key={key}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            let confirm_deletion = '';
                                            if (
                                                values['confirm_deletion'] !==
                                                    null &&
                                                values['confirm_deletion'] !==
                                                    undefined
                                            ) {
                                                confirm_deletion = values[
                                                    'confirm_deletion'
                                                ].toLowerCase();
                                            }

                                            if (confirm_deletion === 'delete') {
                                                request
                                                    .delete(
                                                        `${config.API_BASE_URL}/share-classes/${classData.class_id}/delete`,
                                                        {
                                                            data: classData,
                                                        }
                                                    )
                                                    .then((response) => {
                                                        enqueueSnackbar(
                                                            'Asset class has been deleted successfully',
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                        setDeleteDialog(false);
                                                        props.capTableActions.getClasses();
                                                    })
                                                    .catch(() => {
                                                        enqueueSnackbar(
                                                            'Error occured while deleting asset class',
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );

                                                        setKey(key + 1);
                                                    });
                                            } else {
                                                enqueueSnackbar(
                                                    'Please type "delete" in the box to confirm request',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                                setKey(key + 1);
                                            }
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Container>
                                                <Form
                                                    className={classes.form}
                                                    noValidate
                                                >
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="confirm_deletion"
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        label={
                                                            <span>
                                                                Type{' '}
                                                                <b className="ihq-red">
                                                                    delete
                                                                </b>{' '}
                                                                in the box to
                                                                confirm your
                                                                request
                                                            </span>
                                                        }
                                                        fullWidth
                                                    />
                                                    <DialogActions
                                                        className={
                                                            classes.actions
                                                        }
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            variant="outlined"
                                                            className={
                                                                classes.secondaryButtonLabel
                                                            }
                                                            onClick={() => {
                                                                closeDialog();
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            variant="outlined"
                                                            className={
                                                                classes.buttonLabel
                                                            }
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </DialogActions>
                                                </Form>
                                            </Container>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <ClassDialog
                        shareClass={classData}
                        dialogIsOpen={infoDialogIsOpen}
                        setDialogIsOpen={setInfoDialogIsOpen}
                    />
                    <EditAuthorizedSharesDialog
                        classData={classData}
                        dialogIsOpen={editAuthorizedSharesDialogIsOpen}
                        setDialogIsOpen={setEditAuthorizedSharesDialogIsOpen}
                    />
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={addCategoryDialog}
                        onBackdropClick={(e) => setAddCategoryDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            New Category
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={{
                                            transfer_restrictions: UNRESTRICTED,
                                        }}
                                        validationSchema={
                                            categoryValidationSchema
                                        }
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            if (shareClass === null) {
                                                enqueueSnackbar(
                                                    'Please select class',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                                setSubmitting(false);
                                            } else {
                                                props.capTableActions.addShareCategory(
                                                    {
                                                        class: shareClass,
                                                        description:
                                                            values[
                                                                'category_description'
                                                            ],
                                                        price: values[
                                                            'category_price'
                                                        ]
                                                            ? values[
                                                                  'category_price'
                                                              ]
                                                            : null,
                                                        transfer_restrictions:
                                                            values[
                                                                'transfer_restrictions'
                                                            ],
                                                        lock_period: values[
                                                            'lock_period'
                                                        ]
                                                            ? values[
                                                                  'lock_period'
                                                              ]
                                                            : null,
                                                        current_price: values[
                                                            'current_price'
                                                        ]
                                                            ? values[
                                                                  'current_price'
                                                              ]
                                                            : null,
                                                    },
                                                    setSubmitting,
                                                    newCategorySuccessCallback
                                                );
                                            }
                                        }}
                                    >
                                        {({ values, isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid>
                                                    {props.classes &&
                                                    listClasses &&
                                                    listClasses.length >
                                                        0 ? null : (
                                                        <Grid
                                                            className={
                                                                classes.redText
                                                            }
                                                        >
                                                            <h3>
                                                                You do not have
                                                                any classes
                                                                created. Please
                                                                create a class
                                                                before adding a
                                                                new category
                                                            </h3>
                                                        </Grid>
                                                    )}
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Category Name"
                                                        name={
                                                            'category_description'
                                                        }
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        placeholder={
                                                            'Apple Founder Asset'
                                                        }
                                                        inputProps={{
                                                            maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                                        }}
                                                    />

                                                    {props.classes &&
                                                    listClasses &&
                                                    listClasses.length > 0 ? (
                                                        <Grid>
                                                            <Field
                                                                component={
                                                                    FormikSelect
                                                                }
                                                                name="class"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    id: 'class',
                                                                }}
                                                                fullWidth
                                                                label="Class *"
                                                                value={
                                                                    shareClass !==
                                                                    null
                                                                        ? shareClass
                                                                        : listClasses[0]
                                                                              .class_description
                                                                }
                                                                onChange={(e) =>
                                                                    setShareClass(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            >
                                                                {' '}
                                                                >
                                                                {listClasses.length >
                                                                    0 &&
                                                                    listClasses.map(
                                                                        (
                                                                            class_item,
                                                                            i
                                                                        ) => (
                                                                            <MenuItem
                                                                                value={
                                                                                    class_item.class_description
                                                                                }
                                                                                key={
                                                                                    class_item.class_description
                                                                                }
                                                                            >
                                                                                {
                                                                                    class_item.class_description
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Field>
                                                        </Grid>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <RadioButtons
                                                        label="Transfer restrictions"
                                                        name="transfer_restrictions"
                                                        options={[
                                                            {
                                                                label:
                                                                    'Unrestricted',
                                                                value: UNRESTRICTED,
                                                            },
                                                            {
                                                                label:
                                                                    'Restricted',
                                                                value: RESTRICTED,
                                                            },
                                                            {
                                                                label: 'Custom',
                                                                value: CUSTOM,
                                                            },
                                                        ]}
                                                    />
                                                    {values.transfer_restrictions ===
                                                        CUSTOM && (
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="number"
                                                            name="lock_period"
                                                            variant="outlined"
                                                            margin="normal"
                                                            label={
                                                                'Lock period (for transfers)'
                                                            }
                                                            placeholder={'90'}
                                                            fullWidth
                                                        />
                                                    )}
                                                    <Field
                                                        component={TextField}
                                                        name="current_price"
                                                        type="text"
                                                        label="Current price"
                                                        variant="outlined"
                                                        margin="normal"
                                                        numeric={1}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <DialogActions
                                                    className={classes.actions}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        className={
                                                            classes.secondaryButtonLabel
                                                        }
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                        disabled={isSubmitting}
                                                    >
                                                        {!isSubmitting ? (
                                                            <span>Save</span>
                                                        ) : (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({ profile, capTable, classes }) => {
    return {
        profile: profile,
        capTable: capTable.classes.data,
        classes: capTable.classes,
        categories: capTable.categories.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClassInfo);
