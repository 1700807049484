import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    GetRandomString,
    sumObject,
} from '../../../utilities/utils';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import transferStyles from '../../../components/styles/transferStyles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '../../../components/TextField/TextField';
import Paper from '@mui/material/Paper';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ComboBox from '../../../components/Form/ComboBox';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import * as capTableActions from '../../../store/capTable/capTableActions';
import CircularProgress from '@mui/material/CircularProgress';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
 import { TRANSFER } from '../../../constants/transfers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Approvals from './Approvals';
import History from './History';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import Humanize from 'humanize-plus';

import {
    validateFileExtension,
    allowedFiles,
    allowedFilesMessage,
} from '../../../utilities/utils';
import useShareClasses from '../../../api/useShareClasses';
import useShareCategories from '../../../api/useShareCategories';
import useShareholders from '../../../api/useShareholders';
import useShareholdersByCategory from '../../../api/useShareholdersByCategory';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import { snackbar } from '../../../utilities/snackbarUtils';
import request from '../../../utilities/request';
import * as config from './../../../config';
import {
    Avatar,
    Divider,
    IconButton,
    InputAdornment,
    Tooltip,
} from '@mui/material';
const formData = new FormData();
formData.append('document', '');

const Transfers = (props) => {
    const classes = authStyles();
    let transfer_reasons = [
        {
            value: 'GIFT',
            text: 'Gift',
        },
        {
            value: 'SALE',
            text: 'Sale',
        },
        {
            value: 'INHERITANCE',
            text: 'Inheritance',
        },
        {
            value: 'OTHER',
            text: 'Other',
        },
    ];

    const transferClasses = transferStyles();

    const [total, setTotal] = useState(null);
    const [kyc, setKyc] = useState(false);
    const [key, setKey] = useState(GetRandomString(8));

    let [read] = useState(0);

    const [transferDocument, setTransferDocument] = useState('');

    const defaultValues = {
        reason: 'GIFT',
        share_class_id: '',
        equity_id: '',
        price_per_share: '',
        is_routine: true,
        senders: [{ sender: '', amount: null }],
        receivers: [{ receiver: '', amount: null }],
        type: TRANSFER
    };

    const schema = yup
        .object({
            share_class_id: yup
                .string()
                .required('You have to select a asset class.')
                .nullable(),
            equity_id: yup
                .string()
                .required('You have to select a asset category.')
                .nullable(),
            senders: yup.array().of(
                yup.object().shape({
                    sender: yup
                        .string()
                        .required('You have to select a sender.')
                        .nullable(),
                    amount: yup
                        .string()
                        .required('Please enter the transfer amount.')
                        .matches(
                            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
                            'Please enter a valid amount.'
                        )
                        .nullable(),
                })
            ),
            receivers: yup.array().of(
                yup.object().shape({
                    receiver: yup
                        .string()
                        .required('You have to select a receiver.')
                        .nullable(),
                    amount: yup
                        .string()
                        .required('Please enter the transfer amount.')
                        .matches(
                            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
                            'Please enter a valid amount.'
                        )
                        .nullable(),
                })
            ),
            // from_wallet: yup.string().required('You have to enter an amount.').nullable(),
            // to_wallet: yup.string().required('You have to enter an amount.').nullable(),
            price_per_share: yup
                .number()
                .nullable()
                .required('Please enter price per asset.'),
            reason: yup.string().required('Please enter transfer reason.'),
            is_routine: yup.bool().required('Please select transfer priority.'),
        })
        .required();

    const {
        watch,
        handleSubmit,
        control,
        resetField,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    /* Multiple senders control */
    const {
        fields: sFields,
        append: sendersAppend,
        remove: sendersRemove,
    } = useFieldArray({
        control,
        name: 'senders',
    });
    const watchSendersFieldArray = watch('senders');
    const sendersFields = sFields.map((field, index) => {
        return {
            ...field,
            ...watchSendersFieldArray[index],
        };
    });

    /* Multiple receivers control */
    const {
        fields: rFields,
        append: receiversAppend,
        remove: receiversRemove,
    } = useFieldArray({
        control,
        name: 'receivers',
    });
    const watchReceiversFieldArray = watch('receivers');
    const receiversFields = rFields.map((field, index) => {
        return {
            ...field,
            ...watchReceiversFieldArray[index],
        };
    });

    useEffect(() => {
        setTotal(sumObject(watchReceiversFieldArray, 'amount'));
        validateKYC();
    }, [sendersFields, receiversFields]);

    const validateTotals = (data) => {
        let sendAmount = 0;
        let receiveAmount = 0;

        for (let sender of data.senders) {
            sendAmount += Number(sender.amount);
        }

        for (let receiver of data.receivers) {
            receiveAmount += Number(receiver.amount);
        }

        if (sendAmount.toFixed(2) !== receiveAmount.toFixed(2)) {
            return false;
        }

        return true;
    };

    /* Validate KYCs */
    const validateKYC = () => {
        let kycIsValid = true;
        for (let receiver of receiversFields) {
            let receiverObject = _.find(shareholders, {
                investor_id: receiver.receiver,
            });
            if (receiverObject) {
                kycIsValid =
                    kycIsValid & (receiverObject?.kyc_status === 'Approved');
            }
        }

        setKyc(kycIsValid);
    };

    const getShareholder = (array, id) => {
        return _.find(array, { investor_id: id });
    };

    const onSubmit = (data) => {
        if (!validateTotals(data)) {
            snackbar.error('Sending total MUST match the receiving total.');
            return;
        }

        request
            .post(`${config.API_BASE_URL}/transfers/issuer`, data)
            .then((response) => {
                snackbar.success('Transfer request submitted successfully.');
                reset(defaultValues);
                setKey(GetRandomString(8));
            })
            .catch(() => {
                snackbar.error('An error occurred while saving transfer.');
            });
    };

    const {
        shareClasses,
        shareClassesLoading,
        defaultShareClass,
    } = useShareClasses();
    const { shareholders, shareholdersLoading } = useShareholders();

    const classId = watch('share_class_id', null);
    const { shareCategories, shareCategoriesLoading } = useShareCategories(
        classId
    );

    const categoryId = watch('equity_id', null);
    const {
        shareholders: categoryShareholders,
        shareholdersLoading: categoryShareholdersLoading,
    } = useShareholdersByCategory(categoryId);

    const sharePrice = watch('price_per_share', null);

    useEffect(() => {
        if (props.classes && props.classes.length && read !== 1) {
            props.capTableActions.getCategoriesByClassId(props.classes[0].id);
            if (props.categories && props.categories.length) {
                props.capTableActions.getShareholdersByCategory(
                    props.categories[0].id
                );
            }
        }
    }, [
        props.capTableActions,
        props.classes,
        props.categories,
        props.shareholders,
    ]);

    const uploadFile = (file) => {
        if (!validateFileExtension(file)) {
            snackbar.error(allowedFilesMessage);
        } else {
            formData.set('document', file, file.name);
            setTransferDocument(file.name);
        }
    };

    return (
        <LockedTile
            requiredPlan={SUBSCRIPTION_PRO}
            lock={false}
        >
            <form onSubmit={handleSubmit(onSubmit)} key={key}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography className={classes.title}>
                                Transfer shares
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className={classes.label}>
                                        Select class
                                    </InputLabel>

                                    <ComboBox
                                        options={shareClasses}
                                        optionLabel={'class_description'}
                                        disabled={shareClassesLoading}
                                        loading={shareClassesLoading}
                                        // label="Asset class"
                                        name="share_class_id"
                                        error={errors.share_class_id}
                                        control={control}
                                        defaultValue={defaultShareClass}
                                        callbacks={[
                                            () => resetField('equity_id'),
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className={classes.label}>
                                        Select category
                                    </InputLabel>
                                    <ComboBox
                                        options={shareCategories}
                                        optionLabel={'description'}
                                        disabled={
                                            !shareClasses ||
                                            shareCategoriesLoading ||
                                            !classId
                                        }
                                        loading={shareCategoriesLoading}
                                        // label="Asset category"
                                        name="equity_id"
                                        error={errors.equity_id}
                                        control={control}
                                        dependsOn={classId}
                                        callbacks={[
                                            () => resetField('senders'),
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Divider textAlign="left">
                                <Typography variant="button">
                                    Sender(s)
                                </Typography>
                            </Divider>
                            <Grid container spacing={3}>
                                {sendersFields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        <Grid item xs={12} sm={8}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                From
                                            </InputLabel>
                                            <ComboBox
                                                options={categoryShareholders}
                                                optionLabel={'investor'}
                                                valueLabel="investor_id"
                                                disabled={
                                                    !shareCategories ||
                                                    categoryShareholdersLoading ||
                                                    !categoryId
                                                }
                                                loading={
                                                    categoryShareholdersLoading
                                                }
                                                label="Investor"
                                                name={`senders.${index}.sender`}
                                                placeholder="Search..."
                                                error={
                                                    errors?.['senders']?.[
                                                        index
                                                    ]?.['sender']
                                                }
                                                leftIcon={<SearchIcon />}
                                                control={control}
                                                dependsOn={categoryId}
                                            />
                                            <Typography variant="caption">
                                                {getShareholder(
                                                    categoryShareholders,
                                                    field.sender
                                                ) && (
                                                    <>
                                                        <span>
                                                            KYC status:&nbsp;
                                                            <b
                                                                style={{
                                                                    color:
                                                                        getShareholder(
                                                                            categoryShareholders,
                                                                            field.sender
                                                                        )
                                                                            ?.kyc_status ===
                                                                        'Approved'
                                                                            ? 'green'
                                                                            : 'red',
                                                                }}
                                                            >
                                                                {
                                                                    getShareholder(
                                                                        categoryShareholders,
                                                                        field.sender
                                                                    )
                                                                        ?.kyc_status
                                                                }
                                                            </b>
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Shares:&nbsp;
                                                            <b>
                                                                {Humanize.formatNumber(
                                                                    getShareholder(
                                                                        categoryShareholders,
                                                                        field.sender
                                                                    )?.shares,
                                                                    2
                                                                )}
                                                            </b>
                                                        </span>
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Sent Amount
                                            </InputLabel>
                                            <Input
                                                name={`senders.${index}.amount`}
                                                error={
                                                    errors?.['senders']?.[
                                                        index
                                                    ]?.['amount']
                                                }
                                                control={control}
                                                numeric
                                                endAdornment={
                                                    sendersFields.length !==
                                                        1 && (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Remove sender">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        sendersRemove(
                                                                            index
                                                                        )
                                                                    }
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                >
                                                                    <PersonOffIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}

                                <Grid item xs={12}>
                                    <Divider>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            onClick={() =>
                                                sendersAppend({
                                                    sender: '',
                                                    amount: null,
                                                })
                                            }
                                        >
                                            <AddIcon />
                                            Add sender
                                        </Button>
                                    </Divider>
                                </Grid>
                            </Grid>
                            <br />
                            <Divider textAlign="left">
                                <Typography variant="button">
                                    Receiver(s)
                                </Typography>
                            </Divider>
                            <Grid container spacing={3}>
                                {receiversFields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        <Grid item xs={12} sm={8}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                To
                                            </InputLabel>
                                            <ComboBox
                                                options={shareholders}
                                                optionLabel={'investor'}
                                                valueLabel="investor_id"
                                                disabled={
                                                    !shareholders ||
                                                    shareholdersLoading
                                                }
                                                loading={shareholdersLoading}
                                                label="Investor"
                                                name={`receivers.${index}.receiver`}
                                                placeholder="Search..."
                                                error={
                                                    errors?.['receivers']?.[
                                                        index
                                                    ]?.['receiver']
                                                }
                                                leftIcon={<SearchIcon />}
                                                control={control}
                                            />
                                            <Typography variant="caption">
                                                {getShareholder(
                                                    shareholders,
                                                    field.receiver
                                                ) && (
                                                    <>
                                                        <span>
                                                            KYC status:&nbsp;
                                                            <b
                                                                style={{
                                                                    color:
                                                                        getShareholder(
                                                                            shareholders,
                                                                            field.receiver
                                                                        )
                                                                            ?.kyc_status ===
                                                                        'Approved'
                                                                            ? 'green'
                                                                            : 'red',
                                                                }}
                                                            >
                                                                {
                                                                    getShareholder(
                                                                        shareholders,
                                                                        field.receiver
                                                                    )
                                                                        ?.kyc_status
                                                                }
                                                            </b>
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Shares:&nbsp;
                                                            <b>
                                                                {Humanize.formatNumber(
                                                                    getShareholder(
                                                                        shareholders,
                                                                        field.receiver
                                                                    )?.shares,
                                                                    2
                                                                )}
                                                            </b>
                                                        </span>
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Received Amount
                                            </InputLabel>
                                            <Input
                                                name={`receivers.${index}.amount`}
                                                error={
                                                    errors?.['receivers']?.[
                                                        index
                                                    ]?.['amount']
                                                }
                                                control={control}
                                                numeric
                                                endAdornment={
                                                    receiversFields.length !==
                                                        1 && (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Remove receiver">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        receiversRemove(
                                                                            index
                                                                        )
                                                                    }
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                >
                                                                    <PersonOffIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid item xs={12}>
                                    <Divider>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            onClick={() =>
                                                receiversAppend({
                                                    receiver: '',
                                                    amount: null,
                                                })
                                            }
                                        >
                                            <AddIcon />
                                            Add receiver
                                        </Button>
                                    </Divider>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className={classes.label}>
                                        Share price
                                    </InputLabel>
                                    <Input
                                        name="price_per_share"
                                        error={errors.price_per_share}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <InputLabel className={classes.label}>
                                        Routine/Non-routine{' '}
                                        <HelpTooltip
                                            title={
                                                <div>
                                                    Routine if it does not{' '}
                                                    <br />
                                                    (1) require requisitioning
                                                    certificates of an issue for
                                                    which the transfer agent,
                                                    under the terms of its
                                                    agency, does not maintain a
                                                    supply of certificates;{' '}
                                                    <br />
                                                    (2) include a certificate as
                                                    to which the transfer agent
                                                    has received notice of a
                                                    stop order, adverse claim,
                                                    or any other restriction on
                                                    transfer; <br />
                                                    (3) require any additional
                                                    certificates, documentation,
                                                    instructions, assignments,
                                                    guarantees, endorsements,
                                                    explanations, or opinions of
                                                    counsel before transfer may
                                                    be effected; <br />
                                                    (4) require review of
                                                    supporting documentation
                                                    other than assignments,
                                                    endorsements or stock
                                                    powers, certified corporate
                                                    resolutions, signature, or
                                                    other common and ordinary
                                                    guarantees, or appropriate
                                                    tax, or tax waivers; <br />
                                                    (5) involve a transfer in
                                                    connection with a
                                                    reorganization, tender
                                                    offer, exchange, redemption,
                                                    or liquidation; <br />
                                                    (6) include a warrant,
                                                    right, or convertible
                                                    security presented for
                                                    transfer of record ownership
                                                    within five business days
                                                    before any day upon which
                                                    exercise or conversion
                                                    privileges lapse or change;{' '}
                                                    <br />
                                                    (7) include a warrant,
                                                    right, or convertible
                                                    security presented for
                                                    exercise or conversion; or{' '}
                                                    <br />
                                                    (8) include a security of an
                                                    issue which within the
                                                    previous 15 business days
                                                    was offered to the public,
                                                    pursuant to a registration
                                                    statement effective under
                                                    the Securities Act of 1933,
                                                    in an offering not of a
                                                    continuing nature.
                                                    <br />
                                                </div>
                                            }
                                        />
                                    </InputLabel>
                                    <Select
                                        name="reason"
                                        options={[
                                            { value: true, text: 'ROUTINE' },
                                            {
                                                value: false,
                                                text: 'NON-ROUTINE',
                                            },
                                        ]}
                                        default={0}
                                        error={errors.is_routine}
                                        control={control}
                                    />
                                </Grid>{' '}
                            </Grid>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <InputLabel className={classes.label}>
                                        Transfer Reason
                                    </InputLabel>
                                    <Select
                                        name="reason"
                                        options={transfer_reasons}
                                        default={0}
                                        error={errors.reason}
                                        control={control}
                                    />
                                </Grid>{' '}
                                <Grid item xs={12} sm={6} md={6}>
                                    <InputLabel className={classes.label}>
                                        Document
                                    </InputLabel>
                                    <TextField
                                        id="standard-basic"
                                        variant="outlined"
                                        value={transferDocument}
                                        placeholder=""
                                    />
                                    <Button
                                        sx={{ float: 'right' }}
                                        color="primary"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                    >
                                        Browse
                                        <input
                                            onChange={(e) => {
                                                uploadFile(e.target.files[0]);
                                            }}
                                            style={{
                                                display: 'none',
                                            }}
                                            type="file"
                                            name="document"
                                            accept={allowedFiles.join(',')}
                                        />
                                    </Button>{' '}
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5} md={5}></Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography className={classes.title}>
                                Confirm Transfer
                            </Typography>

                            <List>
                                <Typography variant="button">
                                    <b>Sender(s):</b>
                                </Typography>
                                {sendersFields.map((sender, index) => {
                                    let s = _.find(categoryShareholders, {
                                        investor_id: sender.sender,
                                    });
                                    return (
                                        s && (
                                            <ListItem key={index}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <RemoveIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <span>
                                                            {s.investor}:{' '}
                                                            <b>
                                                                {sender.amount}
                                                            </b>
                                                        </span>
                                                    }
                                                    secondary={
                                                        <>
                                                            <span>
                                                                {s.wallet}
                                                            </span>
                                                            <br />
                                                            <span>
                                                                KYC status:{' '}
                                                                <b
                                                                    style={{
                                                                        color:
                                                                            s.kyc_status ===
                                                                            'Approved'
                                                                                ? 'green'
                                                                                : 'red',
                                                                    }}
                                                                >
                                                                    {
                                                                        s.kyc_status
                                                                    }
                                                                </b>
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    );
                                })}
                                <br />
                                <Typography variant="button">
                                    <b>Receiver(s):</b>
                                </Typography>
                                {receiversFields.map((receiver, index) => {
                                    let r = _.find(shareholders, {
                                        investor_id: receiver.receiver,
                                    });
                                    return (
                                        r && (
                                            <ListItem key={index}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AddIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <span>
                                                            {r.investor}:{' '}
                                                            <b>
                                                                {
                                                                    receiver.amount
                                                                }
                                                            </b>
                                                        </span>
                                                    }
                                                    secondary={
                                                        <>
                                                            <span>
                                                                {r.wallet}
                                                            </span>
                                                            <br />
                                                            <span>
                                                                KYC status:{' '}
                                                                <b
                                                                    style={{
                                                                        color:
                                                                            r.kyc_status ===
                                                                            'Approved'
                                                                                ? 'green'
                                                                                : 'red',
                                                                    }}
                                                                >
                                                                    {
                                                                        r.kyc_status
                                                                    }
                                                                </b>
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    );
                                })}
                                <Divider />
                                <ListItem>
                                    <ListItemAvatar
                                        className={transferClasses.greyTitle}
                                    ></ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <span>
                                                Price per asset:&nbsp;
                                                <b>
                                                    {sharePrice
                                                        ? sharePrice
                                                        : ''}
                                                </b>
                                            </span>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar
                                        className={transferClasses.greyTitle}
                                    ></ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <span>
                                                Total transfer amount:&nbsp;
                                                <b>
                                                    {total
                                                        ? Humanize.formatNumber(
                                                              total
                                                          ) +
                                                          ' ' +
                                                          _.find(
                                                              shareCategories,
                                                              { id: categoryId }
                                                          )?.description
                                                        : ''}
                                                </b>
                                            </span>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <br />
                            <div className={classes.buttonGroupAlignment}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                    className={`classes.buttonLabel classes.buttonGroupAlignment`}
                                    disabled={!kyc}
                                >
                                    {true ? (
                                        <div className="flex-left">
                                            Submit for approval
                                        </div>
                                    ) : (
                                        <CircularProgress size={24} />
                                    )}
                                </Button>
                            </div>
                            {!kyc && (
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: 'center' }}
                                >
                                    <br />
                                    <b>* Note:</b> All transfer participants
                                    need to be KYC'd for the transfer to be
                                    possible.
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </form>
            {/* </Form>
                )}
            </Formik> */}
            <Approvals />
            <br />
            <History />
        </LockedTile>
    );
};

const mapStateToProps = ({
    profile,
    capTable,
    categories,
    shareholders,
    investors,
}) => {
    return {
        profile: profile,
        classes: capTable.classes.data,
        categories: capTable.categories.data,
        shareholders: capTable.shareholders.data,
        investors: capTable.investors.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
