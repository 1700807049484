import useSWR from "swr"
import { API_BASE_URL } from "../config"
import fetcher from "../utilities/fetcher"

const useShareholders = () => {
    const { data, error } = useSWR(`${API_BASE_URL}/kyc`, fetcher)

    const totalShareholders = data?.data?.total_shareholders ?? 0;
    const totalKyc = data?.data?.total_kyc ?? 0;
    const totalNotKyc = totalShareholders - totalKyc;
    
    return {
        shareholders: data?.data?.kyc,
        overview: {
            total_shareholders: totalShareholders,
            total_kyc: totalKyc,
            total_not_kyc: totalNotKyc,
        },
        shareholdersLoading: !error && !data,
        shareholdersError: error
    }
}

export default useShareholders